
import Vue from 'vue';
import { AccountLinking, FacebookCampaign, FacebookAccountLink, FacebookConnectionsLink } from '../../../types/ads';
import { RefreshToSeeUpdatedDate } from './constants';

export default Vue.extend({
  name: 'linearAdminFacebookAdsNew',
  components: {
    FacebookAccountsNew: () => import('./fbAccountsNew.vue'),
  },
  props: ['editRights', 'advertiser'],
  data: (): {
    isSelectedLoading: boolean;
    isValidConnectionLoading: boolean;
    connectionData: object[] | null;
    facebookData: object[] | null;
    isSavingLoading: boolean;
    bus: Vue;
    facebookConnector: object | null;
    validConnections: object[] | null;
    RefreshToSeeUpdatedDate: string;
  } => ({
    isSelectedLoading: false,
    isValidConnectionLoading: false,
    connectionData: null,
    facebookData: null,
    isSavingLoading: false,
    bus: new Vue(),
    facebookConnector: null,
    validConnections: null,
    RefreshToSeeUpdatedDate,
  }),
  async created() {
    this.isSelectedLoading = true;
    this.isValidConnectionLoading = true;
    Promise.all([this.loadFacebookConnector(), this.loadValidConnections()]).finally(() => {
      this.isSelectedLoading = false;
      this.isValidConnectionLoading = false;
    });
  },
  computed: {
    facebookEnabled(): boolean {
      return !this.loading && this.facebookConnector?.length > 0;
    },
    loading(): boolean {
      return (
        this.isSelectedLoading ||
        this.isValidConnectionLoading ||
        this.$store.state.agencyAdmin.loading ||
        this.$store.state.linearAdmin.loading ||
        this.$store.state.linearAdmin.isSelectedLoading
      );
    },
    selectedAccounts(): Array<FacebookConnectionsLink> {
      if (!this.facebookConnector) return [];
      return this.facebookConnector.accounts;
    },
    accountLinking(): AccountLinking {
      const { accountLinking } = this.$store.state.linearAdmin;
      if (!Array.isArray(accountLinking.FacebookAds.AccountIds)) {
        accountLinking.FacebookAds.AccountIds = [];
      }
      if (!Array.isArray(accountLinking.FacebookAds.CampaignIds)) {
        accountLinking.FacebookAds.CampaignIds = [];
      }
      return accountLinking;
    },
    hasFacebookSocial(): boolean {
      return this.scopedAccountLinking?.FacebookAds?.UseClientFacebook;
    },
    filteredConnectionData(): Array<FacebookAccountLink> {
      if (!this.connectionData) return [];
      const agency = this.connectionData?.AgencyOwned ? this.connectionData.AgencyOwned : [];
      return agency;
    },
  },
  methods: {
    async loadValidConnections(): Promise<void> {
      this.isValidConnectionLoading = true;
      try {
        this.validConnections = await this.$store.dispatch('linearAdmin/getFacebookAvailableCampaigns', {
          advertiserId: this.advertiser.id,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isValidConnectionLoading = false;
      }
    },
    async loadFacebookConnector(): Promise<void> {
      try {
        this.facebookConnector = await this.$store.dispatch('linearAdmin/getFacebookConnector', {
          advertiserId: this.advertiser.id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async saveFBAds() {
      this.isSavingLoading = true;
      try {
        const data = await this.$store.dispatch('linearAdmin/saveFacebookConnector', {
          advertiserId: this.advertiser.id,
          connector: this.facebookConnector,
        });
        if (data) {
          this.loadFacebookConnector();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSavingLoading = false;
      }
    },
    async getAccountLinking(): Promise<void> {
      await this.$store.dispatch('linearAdmin/getAccountLinking', {
        client: false,
        id: this.advertiser.id,
      });
    },
    getUserAccount(userId: string): object {
      const userAccount = this.scopedAccountLinking?.FacebookAds?.AdLinks.filter(fb => fb.UserId.includes(userId));
      if (!userAccount.length) {
        const connectionsAccount = this.filteredConnectionData.filter(fb => fb.UserId.includes(userId));
        const newUser = {
          UserId: connectionsAccount[0].UserId,
          UserSource: connectionsAccount[0].UserSource,
          AccountIds: [],
          CampaignIds: [],
        };
        this.scopedAccountLinking.FacebookAds.AdLinks.push(newUser);
        return newUser;
      }
      return Array.isArray(userAccount) ? userAccount[0] : null;
    },
    toggleCampaign(campaign: FacebookCampaign, account: any, fbAccount: any): void {
      let facebookAccount = this.facebookConnector?.find(
        foundFbAccount => foundFbAccount.facebook_account_id === fbAccount.facebook_account_id,
      );
      if (!facebookAccount) {
        // add fbAccount
        facebookAccount = fbAccount;
        facebookAccount.accounts = [];
        this.facebookConnector?.push(facebookAccount);
      }
      let foundAccount = facebookAccount.accounts.find(
        connectedAccount => account.account_id === connectedAccount.account_id,
      );
      if (!foundAccount) {
        // add account
        foundAccount = account;
        foundAccount.campaigns = [];
        facebookAccount.accounts.push(foundAccount);
      }
      const foundCampaign = foundAccount.campaigns.find(
        connectedCampaign => connectedCampaign.campaign_id === campaign.campaign_id,
      );
      if (!foundCampaign) {
        const newCampaign = {
          campaign_name: campaign.campaign_name,
          campaign_id: campaign.campaign_id,
        };
        foundAccount.campaigns.push(newCampaign);
      } else {
        // remove campaign
        foundAccount.campaigns = foundAccount.campaigns.filter(c => c.campaign_id !== foundCampaign.campaign_id);
        if (foundAccount.campaigns.length === 0) {
          facebookAccount.accounts = facebookAccount.accounts.filter(a => a.account_id !== foundAccount.account_id);
        }
        if (facebookAccount.accounts.length === 0) {
          this.facebookConnector = this.facebookConnector.filter(
            a => a.facebook_account_id !== facebookAccount.facebook_account_id,
          );
        }
      }
    },
    removeCampaign(campaignId: string, accountId: string, fbAccountId: string): void {
      const facebookAccount = this.facebookConnector.find(
        foundFbAccount => foundFbAccount.facebook_account_id === fbAccountId,
      );
      const foundAccount = facebookAccount.accounts.find(connectedAccount => connectedAccount.account_id === accountId);
      foundAccount.campaigns = foundAccount.campaigns.filter(c => c.campaign_id !== campaignId);
    },
  },
});
